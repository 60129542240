import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFeaturedVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import {
  canShowCancelSubscription,
  canShowSubscription,
} from 'widget/selectors/subscription';
import { getMainVideo } from 'widget/selectors/get-video';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
} from 'widget/selectors/video-playback-status';
import {
  canShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
  canShowChannelTitle,
  isVerticalLayoutMobile,
} from 'shared/selectors/app-settings';
import { getOverlayContent } from 'shared/selectors/player';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';
import INFO_OVERLAY_CONTENT from 'widget/player-widget/components/new-player/containers/animated-overlay-content/info-overlay-content-enums';
import {
  VERTICAL_LAYOUT_HEADER_HEIGHT,
  VERTICAL_LAYOUT_VIDEO_HEIGHT,
  VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM,
  VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT,
  DEFAULT_WIDGET_HEIGHT,
  DEFAULT_WIDGET_WIDTH,
} from 'widget/constants/sizes';
import {
  getChannelVideos,
  getCurrentPageIndex,
  getVideosPerPageCount,
} from 'widget/redux/client/lazy-channel-videos/selectors';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { isPortableDevice } from 'shared/selectors/form-factor';

export const canShowActionBar = createSelector(
  canShowChannelTitle,
  getCanShowSignIn,
  getCanShowChannelInfo,
  canShowChannelShare,
  canShowCancelSubscription,
  canShowSubscription,
  (...args) => _.some(args),
);

export const showAutoPlay = createSelector(
  getMainVideo,
  isPortableDevice,
  (mainVideo, isPortableDevice) =>
    !!mainVideo &&
    !(mainVideo.videoSource === VIDEO_SOURCES.FACEBOOK && isPortableDevice) &&
    !(mainVideo.videoSource === VIDEO_SOURCES.VIMEO && isPortableDevice),
);

export const isAllCardsOverlayVisible = createSelector(
  getOverlayContent,
  overlayContent => overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS,
);

export const isPlayerActive = createSelector(
  isAllCardsOverlayVisible,
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
  (isAllCardsOverlayVisible, isVideoPlaying, isVideoPaused) =>
    isAllCardsOverlayVisible || isVideoPlaying || isVideoPaused,
);

export const getVerticalVisibleVideos = createSelector(
  getCurrentPageIndex,
  getChannelVideos,
  getFeaturedVideoId,
  getVideosPerPageCount,
  (currentPageIndex, channelVideos, featuredVideoId, videosPerPage) => {
    if (featuredVideoId) {
      const featuredVideoIndex = _.findIndex(
        channelVideos,
        ({ id }) => id === featuredVideoId,
      );
      const featuredVideo = channelVideos[featuredVideoIndex];
      channelVideos.splice(featuredVideoIndex, 1);
      channelVideos.unshift(featuredVideo);
    }

    return _.slice(channelVideos, 0, (currentPageIndex + 1) * videosPerPage);
  },
);

export const getWidgetSize = createSelector(
  isVerticalLayoutMobile,
  getVerticalVisibleVideos,
  state => state.windowSize,
  viewModeSelectors.isSiteMode,
  (isVertical, verticalVisibleVideos, windowSize, isSite) => {
    if (isVertical) {
      const visibleVideosCount = verticalVisibleVideos.length;
      const width = DEFAULT_WIDGET_WIDTH;
      let height = DEFAULT_WIDGET_HEIGHT;

      if (visibleVideosCount > 0) {
        height =
          VERTICAL_LAYOUT_HEADER_HEIGHT +
          visibleVideosCount *
            (VERTICAL_LAYOUT_VIDEO_HEIGHT +
              VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM) +
          VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT;
      }

      return {
        height,
        width,
      };
    }

    // Previously users had a possibility to resize widget height. Now widget calculates height by itself.
    // But some users had already developed site layouts based on old widget height.
    // So, we do not touch widget height in view mode unless user will not try to change it in edit mode,
    // then widget will recalculate height.
    if (isSite) {
      const { height, width } = windowSize;
      return {
        height: Math.max(height, DEFAULT_WIDGET_HEIGHT),
        width,
      };
    }

    return {
      width: DEFAULT_WIDGET_WIDTH,
      height: DEFAULT_WIDGET_HEIGHT,
    };
  },
);

export const getShareModalTopPosition = videoIndex =>
  VERTICAL_LAYOUT_HEADER_HEIGHT +
  videoIndex *
    (VERTICAL_LAYOUT_VIDEO_HEIGHT + VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM) +
  VERTICAL_LAYOUT_VIDEO_HEIGHT / 2;
