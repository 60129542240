import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Thumbnail from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/thumbnail';
import ThumbnailContent from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/content/content';
import ThumbnailButtons from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/buttons/buttons';
import ThumbnailLiveLabel from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/live-label/live-label';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from 'shared/utils/withStyles';
import styles from './video-thumbnail.styl';
import { TEXT_ALIGNMENT_VALUES } from '@wix/wix-vod-constants/dist/app-settings/text-alignment-values';

import {
  canShowVideoListItemTitle,
  getVideoListPublisherVisibility,
  canShowVideoListItemDuration,
  canShowVideoListItemDescription,
  canShowVideoListItemContentBelow,
  isButtonsShownOnHover,
  isButtonsTextShown,
  getTextAlignment,
  getUseOptimalFontSizes,
  getTitleFont,
  getTextFont,
  getLocale,
  isRTL,
} from 'shared/selectors/app-settings';

import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { getNormalizedCachedDimensions } from '@wix/wix-vod-shared/dist/src/common/utils/normalize-image-dimensions';
import { createGetPlayButtonConfig } from 'widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from 'widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import formatMessageWithPrice from 'shared/utils/format-message-with-price';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import {
  isStreamingLive,
  isScheduledLive,
  isPendingLiveStream,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import getResizedImageUrl from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';

const alignmentMap = {
  [TEXT_ALIGNMENT_VALUES.LEFT]: 'left',
  [TEXT_ALIGNMENT_VALUES.CENTER]: 'center',
  [TEXT_ALIGNMENT_VALUES.RIGHT]: 'right',
};

@connect(() => {
  const getPlayButtonConfig = createGetPlayButtonConfig();
  const getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
  return (state, ownProps) => ({
    playButtonConfig: getPlayButtonConfig(state, ownProps),
    paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
    showTitle: canShowVideoListItemTitle(state),
    showDuration: canShowVideoListItemDuration(state),
    showPublisher: getVideoListPublisherVisibility(state),
    showDescription: canShowVideoListItemDescription(state),
    showContent: canShowVideoListItemContentBelow(state),
    isButtonsShownOnHover: isButtonsShownOnHover(state),
    locale: getLocale(state),
    isButtonsTextShown: isButtonsTextShown(state),
    isPendingLiveStream: isPendingLiveStream(ownProps.videoItem),
    textAlignment: getTextAlignment(state),
    isOptimalFontSizes: getUseOptimalFontSizes(state),
    titleFont: getTitleFont(state),
    textFont: getTextFont(state),
    isPlayerModuleLoaded: state.modules.player,
    isRTL: isRTL(state),
  });
})
@withStyles(styles)
export default class VideoThumbnail extends React.Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    videoItem: PropTypes.object.isRequired,
    breakpoints: PropTypes.object,
    playButtonConfig: PropTypes.object,
    paidAccessButtonConfig: PropTypes.object,
    onPlayWithoutPreviewRequest: PropTypes.func,
    showTitle: PropTypes.bool.isRequired,
    showDuration: PropTypes.bool.isRequired,
    showPublisher: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isButtonsShownOnHover: PropTypes.bool.isRequired,
    isPendingLiveStream: PropTypes.bool.isRequired,
    locale: PropTypes.string,
    isButtonsTextShown: PropTypes.bool.isRequired,
    isContentFocusable: PropTypes.bool.isRequired,
    isOptimalFontSizes: PropTypes.bool.isRequired,
    textAlignment: PropTypes.number.isRequired,
    dataHook: PropTypes.string,
    titleFont: PropTypes.object,
    textFont: PropTypes.object,
    isPlayerModuleLoaded: PropTypes.bool,
    isRTL: PropTypes.bool,
    useResponsiveThumbnail: PropTypes.bool,
    renderThumbnailContent: PropTypes.func,
  };

  renderThumbnailContent() {
    const {
      videoItem,
      onPlayWithoutPreviewRequest,
      showTitle,
      showPublisher,
      showDescription,
      showContent,
      isContentFocusable,
      textAlignment,
      isOptimalFontSizes,
      isRTL,
      titleFont,
      textFont,
      renderThumbnailContent,
      width,
    } = this.props;

    if (!showContent) {
      return null;
    }

    if (renderThumbnailContent) {
      return renderThumbnailContent(videoItem, onPlayWithoutPreviewRequest);
    }

    return (
      <ThumbnailContent
        width={width}
        title={showTitle ? videoItem.title : ''}
        description={showDescription ? videoItem.description : ''}
        publisher={showPublisher ? videoItem.publisher : ''}
        onClick={onPlayWithoutPreviewRequest}
        className={styles.content}
        titleClassName={styles.title}
        textClassName={styles.text}
        isFocusable={isContentFocusable}
        isOptimalFontSizes={isOptimalFontSizes}
        alignment={alignmentMap[textAlignment]}
        titleFont={titleFont}
        textFont={textFont}
        isRTL={isRTL}
      />
    );
  }

  renderThumbnailButtons() {
    const { isPlayerModuleLoaded, isRTL } = this.props;

    const playButtonProps = isPlayerModuleLoaded
      ? this.getPlayButtonProps()
      : {};

    const thumbnailButtonsProps = {
      ...playButtonProps,
      ...this.getBuyButtonProps(),
    };

    return (
      <ThumbnailButtons
        showButtonsText={this.props.isButtonsTextShown}
        isFocusable={this.props.isContentFocusable}
        {...thumbnailButtonsProps}
        className={styles.buttons}
        isRTL={isRTL}
      />
    );
  }

  renderLiveLabel() {
    const {
      videoItem,
      locale,
      isContentFocusable,
      isPendingLiveStream,
      isRTL,
    } = this.props;

    if (!isLiveVideo(videoItem)) {
      return null;
    }

    return (
      <ThumbnailLiveLabel
        videoTitle={videoItem.title}
        startTime={videoItem.dateStartLive}
        isStreaming={isStreamingLive(videoItem)}
        isScheduled={isScheduledLive(videoItem)}
        locale={locale}
        isFocusable={isContentFocusable}
        isPendingLiveStream={isPendingLiveStream}
        liveText={i18n.t('shared.live')}
        pendingLiveText={i18n.t('shared.pending-live')}
        scheduledSoonAriaLabel={i18n.t(
          'widget.accessibility.scheduled-soon-label',
        )}
        scheduledAriaLabel={i18n.t('widget.accessibility.scheduled-label')}
        startsInAriaLabel={i18n.t('widget.accessibility.starts-in')}
        reminderAriaLabel={i18n.t('widget.accessibility.reminder')}
        addReminderText={i18n.t('shared.add-reminder')}
        isRTL={isRTL}
      />
    );
  }

  getPlayButtonProps() {
    const { playButtonConfig } = this.props;

    if (!playButtonConfig) {
      return {
        showPlayButton: false,
      };
    }

    return {
      showPlayButton: true,
      playButtonText: i18n.t(playButtonConfig.translationData.props),
      onPlayClick: this.handlePlayClick,
    };
  }

  handlePlayClick = () => {
    const { playButtonConfig } = this.props;

    if (!playButtonConfig) {
      return;
    }

    playButtonConfig.callback();
  };

  getBuyButtonProps() {
    const { paidAccessButtonConfig } = this.props;

    if (!paidAccessButtonConfig) {
      return {
        showBuyButton: false,
      };
    }

    const {
      currency,
      price,
    } = paidAccessButtonConfig.translationData.priceModel;

    return {
      showBuyButton: true,
      buyButtonText: formatMessageWithPrice(
        paidAccessButtonConfig.translationData.props,
        paidAccessButtonConfig.translationData.priceModel,
      ),

      buyAriaLabel: i18n.t(paidAccessButtonConfig.translationData.props, {
        price: `${currency} ${price}`,
      }),

      onBuyClick: paidAccessButtonConfig.callback,
    };
  }

  getBackgroundSrc() {
    const { videoItem } = this.props;
    return getVideoCoverUrl(videoItem);
  }

  getBackgroundUrl() {
    const { videoItem, width } = this.props;
    const src = getVideoCoverUrl(videoItem);
    const dimensions = getNormalizedCachedDimensions(src, {
      containerWidth: width,
      containerHeight: (width * 9) / 16,
    });

    return getResizedImageUrl({
      url: src,
      width: dimensions[0],
      height: dimensions[1],
    });
  }

  render() {
    const {
      width,
      breakpoints,
      isButtonsShownOnHover,
      videoItem,
      showDuration,
      isLoading,
      isContentFocusable,
      dataHook,
      isRTL,
      useResponsiveThumbnail,
    } = this.props;

    const duration =
      showDuration && videoItem.durationSec ? videoItem.durationStr : '';

    return (
      <Thumbnail
        breakpoints={breakpoints}
        width={width}
        useResponsiveThumbnail={useResponsiveThumbnail || breakpoints}
        isLoading={isLoading}
        dataHook={dataHook}
        onClick={this.handlePlayClick}
        overlayClassName={styles.overlay}
        backgroundUrl={this.getBackgroundUrl()}
        backgroundSrc={this.getBackgroundSrc()}
        buttons={this.renderThumbnailButtons()}
        content={this.renderThumbnailContent()}
        duration={duration}
        liveLabel={this.renderLiveLabel()}
        showButtonsOnHover={isButtonsShownOnHover}
        showLiveLabel={isLiveVideo(videoItem)}
        isFocusable={isContentFocusable}
        ariaLabel={videoItem.title}
        isRTL={isRTL}
      />
    );
  }
}
